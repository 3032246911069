import {add} from "date-fns";
import {Voyage} from "views/ProcessDetails/Process.types";
import {ID_COLLECT_VOYAGE} from "views/ProcessDetails/utils/constants";

const useValidateVoyages = () => {
  const shouldDisableLoad = (currentVoyageIndex: number, voyages: Voyage[]) => {
    /**
     * [] Apenas habilitar caso anterior tenha desembarcado (exceto coleta)
     * [] Desabilitar caso atual tenha desembarcado
     */

    //load da primeira viagem sempre habilitado, exceto se já tiver desembarcado
    if (currentVoyageIndex === 0 && !voyages[currentVoyageIndex]?.cargoUnload) {
      return {disabled: false};
    }

    if (
      voyages[currentVoyageIndex]?.cargoUnload &&
      voyages[currentVoyageIndex]?.cargoLoad
    ) {
      return {disabled: true, message: "Viagem desembarcada"};
    }

    const previousVoyage = voyages[currentVoyageIndex - 1];

    //se for coleta, vai Load vai estar sempre habilitado

    //não desabilita campos se for coleta, mas ela não pode ter datas de load e unload
    //caso tenha uma das datas preenchidas, se comporta como qualquer outro tipo
    //de viagem
    if (
      previousVoyage?.typeVoyage?.id === ID_COLLECT_VOYAGE &&
      !previousVoyage?.cargoLoad &&
      !previousVoyage?.cargoUnload
    ) {
      return {disabled: false};
    }

    if (!previousVoyage?.cargoUnload) {
      return {disabled: true, message: "Desembarcar viagem anterior"};
    } else {
      return {disabled: false};
    }
  };

  const shouldDisableUnload = (
    currentVoyageIndex: number,
    voyages: Voyage[],
  ) => {
    /**
     * [] Apenas habilitar caso anterior tenha desembarcado (exceto coleta)
     * [] Apenas habilitar caso embarque atual tenha sido feito
     */
    //se a viagem 2 estiver embarcada, desabilitar embarque da v1
    //se existe voyage + 1 && voyage[i+1].
    if (
      voyages[currentVoyageIndex + 1] &&
      voyages[currentVoyageIndex + 1]?.cargoLoad
    ) {
      return {disabled: true, message: "Viagem posterior embarcada"};
    }

    const currentVoyage = voyages[currentVoyageIndex];

    if (!currentVoyage?.cargoLoad) {
      return {disabled: true, message: "Embarque não realizado"};
    } else {
      return {disabled: false};
    }

    //se desembarque preenchido, desabilitar embarque
  };

  const minDateVoyageLoad = (currentVoyageIndex: number, voyages: Voyage[]) => {
    /**
     * [] Hoje ou anterior
     * [] Sempre posterior ao desembarque da viagem anterior
     */

    // aqui eu posso considerar que o desembarque existe, caso contrário esse campo
    // será desabilitado.
    const previousVoyageIsCollectAndDoesntHaveUnload =
      !voyages[currentVoyageIndex - 1]?.cargoUnload &&
      voyages[currentVoyageIndex - 1]?.typeVoyage.id === ID_COLLECT_VOYAGE;

    if (
      currentVoyageIndex === 0 ||
      previousVoyageIsCollectAndDoesntHaveUnload
    ) {
      //caso primeira viagem do array ou anterior é coleta pode ser qualquer coisa
      return undefined;
    }

    const result = add(new Date(voyages[currentVoyageIndex - 1]?.cargoUnload), {
      hours: 3,
    });

    return {
      date: result,
      message: "Deve ser posterior ao último desembarque",
    };
  };

  const minDateVoyageUnload = (
    currentVoyageIndex: number,
    voyages: Voyage[],
  ) => {
    /**
     * [] Sempre posterior ao desembarque da viagem anterior
     * [] Não pode data futura
     */

    // [] Mesmo dia ou posterior ao embarque da mesma viagem
    const result = add(new Date(voyages[currentVoyageIndex]?.cargoLoad), {
      hours: 3,
    });

    return {
      date: result,
      message: "Deve ser posterior ao embarque",
    };
  };

  return {
    shouldDisableLoad,
    shouldDisableUnload,
    minDateVoyageLoad,
    minDateVoyageUnload,
  };
};

export default useValidateVoyages;
