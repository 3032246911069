import {DialogActions, Typography} from "@mui/material";
import {Grid, useTheme} from "@mui/system";
import {CustomModal, ProcessDatePicker} from "components";
import pxToRem from "hooks/usePxToRem";
import {useEffect, useRef, useState} from "react";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  CustomAutocomplete,
  CustomButton,
  CustomInput,
  CustomInputWrapper,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandleBoardingOptions from "views/ProcessDetails/components/ProcessBoarding/useHandleBoardingOptions";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {GetVesselsList} from "services/api_v2/Processes/Processes.service";
import useVoyageTypes from "../useVoyageTypes";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";
import {
  ID_COLLECT_VOYAGE,
  ID_INITIAL_VOYAGE,
} from "views/ProcessDetails/utils/constants";
import useValidateVoyages from "views/ProcessDetails/hooks/useValidateVoyages";

export type AddOrEditVoyageData = {
  id?: number;
  typeVoyage?: null | {id?: number; name?: string};
  origin?: null | {id?: number; name?: string};
  destination?: null | {id?: number; name?: string};
  vessel?: null | {id?: number; name?: string};
  voyageFlight?: string;
  estimatedCargoLoad?: string;
  cargoLoad?: string;
  estimatedCargoUnload?: string;
  cargoUnload?: string;
  placePickup?: string;
  precedence?: number;
};

type AddOrEditVoyageModalProps = {
  isOpen: boolean;
  index: number | null;
  handleSubmitVoyage: (data: AddOrEditVoyageData, dirty) => void;
  newVoyage?: "" | "newVoyage";
  handleCloseModal: () => void;
  modalData: AddOrEditVoyageData;
  initialOrigin:
    | {
        id: number;
        name: string;
      }
    | undefined;
};
const AddOrEditVoyageModal = (props: AddOrEditVoyageModalProps) => {
  const {mountOptions} = useChangeDAteAndAutocomplete();
  const {control: processControl} = useFormContext<DefaultProcessDetails>();
  const {fields} = useFieldArray({
    name: "voyages",
    keyName: "formId",
    control: processControl,
  });
  const formRef = useRef<HTMLFormElement | null>(null);
  const formId = "newVoyage";

  const defaultValues: AddOrEditVoyageData = {
    ...props.modalData,
  };
  const {handleSubmit, control, setValue, formState, watch, trigger} = useForm({
    defaultValues: defaultValues,
  });

  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [vesselsOptions, setVesselsOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [voyageTypes, setVoyageTypes] = useState([] as AutoCompleteData[]);

  const {getVoyageTypes, loading: loadingVoyageTypes} = useVoyageTypes();

  const disableVoygeTypeOptions = (option) => {
    if (
      fields?.length === 0 ||
      props?.modalData?.typeVoyage?.id === ID_INITIAL_VOYAGE
    ) {
      return option.id !== ID_INITIAL_VOYAGE;
    } else if (props?.modalData?.typeVoyage?.id === ID_COLLECT_VOYAGE) {
      return option.id !== ID_COLLECT_VOYAGE;
    } else {
      return option.id === ID_INITIAL_VOYAGE;
    }
  };

  const {
    getOptions: getBoardingOptions,
    onBottomScroll: onBottomScrollBoarding,
    isLoadingOptions: isLoadingBoardingOptions,
  } = useHandleBoardingOptions();

  const {
    getOptions: getVesselsOptions,
    onBottomScroll: onBottomScrollVessels,
    isLoadingOptions: isLoadingVesselsOptions,
  } = useHandleFieldLists(GetVesselsList);

  const [searchFields, setSearchFields] = useState({
    vessels: "",
    origin: "",
    destination: "",
  });

  const handleCloseModal = () => {
    props.handleCloseModal();
  };

  const [showHelperText, setShowHelperText] = useState({
    typeVoyage: false,
    origin: false,
    destination: false,
  });

  const {dirtyFields} = formState;
  const onSubmit = (data: AddOrEditVoyageData) => {
    props.handleSubmitVoyage(data, dirtyFields);
  };

  const handleChangeTypeVoyageToCollect = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
  ) => {
    if (value?.label?.toLowerCase() === "coleta") {
      setValue(
        "typeVoyage",
        {
          id: value?.id,
          name: value?.label,
        },
        {shouldDirty: true},
      );
      setValue("precedence", 1, {shouldDirty: true});
      setValue("id", props.modalData?.id, {shouldDirty: true});
      return;
    }
    setValue(
      "typeVoyage",
      {id: value?.id, name: value?.label},
      {shouldDirty: true},
    );
  };

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    name: any,
  ) => {
    if (value === null) {
      setValue(name, {id: null, name: null}, {shouldDirty: true});
      return;
    }
    setValue(name, {id: value?.id, name: value?.label}, {shouldDirty: true});
  };

  const handleFormSubmissionWithValidation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (formRef?.current) {
      const isValid = formRef?.current?.reportValidity();
      if (!isValid) {
        return;
      } else {
        handleSubmit(onSubmit)(e);
      }
    }
  };

  useEffect(() => {
    if (props.initialOrigin) {
      setValue("origin", props.initialOrigin, {shouldDirty: true});
    }
  }, []);

  const voyageNumber = () => {
    if (props.newVoyage && fields?.length) {
      return fields?.length + 1;
    } else if (fields?.length) {
      return Number(props?.index) + 1;
    } else {
      return 1;
    }
  };
  const allVoyages = useWatch({name: `voyages`});

  const {
    minDateVoyageLoad,
    shouldDisableLoad,
    shouldDisableUnload,
    minDateVoyageUnload,
  } = useValidateVoyages();

  const modalVoyage = watch();
  const theme = useTheme();

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={handleCloseModal}
      title={
        props.newVoyage ? "Adicionar nova viagem" : "Editar dados da viagem"
      }
      titleIcon={"directions_boat"}
    >
      <form ref={formRef}>
        <Grid
          container
          columnSpacing={pxToRem(8)}
          rowSpacing={pxToRem(20)}
          sx={{
            padding: pxToRem(20),
          }}
          id={formId}
        >
          <Grid size={12}>
            <Typography variant="subtitle1">{`Viagem ${voyageNumber()}`}</Typography>
          </Grid>
          <CustomInputWrapper title="Tipo de viagem" xs={3} required>
            <Controller
              name={`typeVoyage`}
              control={control}
              render={({field: {value}}) => {
                return (
                  <CustomAutocomplete
                    name={`typeVoyage`}
                    value={value?.name ?? ""}
                    options={voyageTypes}
                    getOptionDisabled={disableVoygeTypeOptions}
                    onOpen={
                      voyageTypes?.length > 0
                        ? undefined
                        : () => getVoyageTypes(setVoyageTypes)
                    }
                    onChange={(e, value) =>
                      handleChangeTypeVoyageToCollect(e, value)
                    }
                    onBlur={() =>
                      setShowHelperText((prev) => ({
                        ...prev,
                        typeVoyage: false,
                      }))
                    }
                    placeholder="Tipo viagem"
                    loading={loadingVoyageTypes}
                    required
                    textFieldProps={{
                      required: true,
                      helperText:
                        showHelperText.typeVoyage && "Preencha este campo.",
                      onInvalid: () =>
                        setShowHelperText((prev) => ({
                          ...prev,
                          typeVoyage: true,
                        })),
                    }}
                  />
                );
              }}
            />
          </CustomInputWrapper>
          <CustomInputWrapper
            title="Origem"
            xs={3}
            required={
              modalVoyage?.typeVoyage?.id === ID_COLLECT_VOYAGE ? false : true
            }
          >
            <Controller
              name={`origin`}
              control={control}
              render={({field: {value}}) => (
                <CustomAutocomplete
                  name={`origin`}
                  placeholder="Origem"
                  optionSecondaryContent
                  tooltipText={getTooltipText(value?.name ?? "")}
                  loading={isLoadingBoardingOptions}
                  options={mountOptions(value, boardingOptions)}
                  value={value?.name ?? ""}
                  onOpen={
                    boardingOptions.length > 0
                      ? undefined
                      : () =>
                          getBoardingOptions(
                            setBoardingOptions,
                            searchFields.origin,
                          )
                  }
                  onInputChange={(e, value) => {
                    if (e !== null) {
                      setSearchFields((prev) => ({
                        ...prev,
                        origin: value,
                      }));
                      getBoardingOptions(setBoardingOptions, value);
                    }
                  }}
                  getNextPage={() => {
                    onBottomScrollBoarding(
                      setBoardingOptions,
                      searchFields.origin,
                    );
                  }}
                  onChange={(e, value) => {
                    handleChangeAutocomplete(e, value, `origin`);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === option?.label
                  }
                  onBlur={() =>
                    setShowHelperText((prev) => ({...prev, origin: false}))
                  }
                  textFieldProps={{
                    required:
                      modalVoyage?.typeVoyage?.id === ID_COLLECT_VOYAGE
                        ? false
                        : true,
                    helperText: showHelperText.origin && "Preencha este campo.",
                    onInvalid: () =>
                      setShowHelperText((prev) => ({...prev, origin: true})),
                  }}
                  required={
                    modalVoyage?.typeVoyage?.id === ID_COLLECT_VOYAGE
                      ? false
                      : true
                  }
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Destino" xs={3} required>
            <Controller
              name={`destination`}
              control={control}
              render={({field: {value}}) => (
                <CustomAutocomplete
                  name={`destination`}
                  placeholder="Destino"
                  optionSecondaryContent
                  tooltipText={getTooltipText(value?.name ?? "")}
                  loading={isLoadingBoardingOptions}
                  options={mountOptions(value, boardingOptions)}
                  value={value?.name ?? null}
                  onOpen={
                    boardingOptions.length > 0
                      ? undefined
                      : () =>
                          getBoardingOptions(
                            setBoardingOptions,
                            searchFields.destination,
                          )
                  }
                  onInputChange={(e, value) => {
                    if (e !== null) {
                      setSearchFields((prev) => ({
                        ...prev,
                        destination: value,
                      }));
                      getBoardingOptions(setBoardingOptions, value);
                    }
                  }}
                  getNextPage={() => {
                    onBottomScrollBoarding(
                      setBoardingOptions,
                      searchFields.destination,
                    );
                  }}
                  onChange={(e, value) => {
                    handleChangeAutocomplete(e, value, `destination`);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === option?.label
                  }
                  onBlur={() =>
                    setShowHelperText((prev) => ({...prev, destination: false}))
                  }
                  textFieldProps={{
                    required: true,
                    helperText:
                      showHelperText.destination && "Preencha este campo.",
                    onInvalid: () =>
                      setShowHelperText((prev) => ({
                        ...prev,
                        destination: true,
                      })),
                  }}
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Navio" xs={3}>
            <Controller
              name={`vessel`}
              control={control}
              render={({field: {value}}) => (
                <CustomAutocomplete
                  name={`vessel`}
                  placeholder="Navio"
                  tooltipText={getTooltipText(value?.name ?? "")}
                  loading={isLoadingVesselsOptions}
                  options={mountOptions(value, vesselsOptions)}
                  value={value?.name ?? ""}
                  onOpen={
                    vesselsOptions?.length > 0
                      ? undefined
                      : () =>
                          getVesselsOptions(
                            setVesselsOptions,
                            1,
                            searchFields.vessels,
                          )
                  }
                  onInputChange={(e, value) => {
                    if (e !== null) {
                      setSearchFields((prev) => ({...prev, vessels: value}));
                      getVesselsOptions(setVesselsOptions, 1, value);
                    }
                  }}
                  getNextPage={() => {
                    onBottomScrollVessels(
                      setVesselsOptions,
                      searchFields.vessels,
                    );
                  }}
                  onChange={(e, value) => {
                    handleChangeAutocomplete(e, value, `vessel`);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === option?.label
                  }
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Viagem" xs={3}>
            <Controller
              name={`voyageFlight`}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomInput
                  name={`voyageFlight`}
                  placeholder="Viagem"
                  value={value ?? ""}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="ETD" xs={3} required>
            <Controller
              name={`estimatedCargoLoad`}
              control={control}
              render={({field: {value}}) => (
                <ProcessDatePicker
                  value={value}
                  name={`estimatedCargoLoad`}
                  title="ETD"
                  required
                  setValue={setValue}
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Embarque" xs={3}>
            <Controller
              name={`cargoLoad`}
              control={control}
              render={({field: {value}}) => (
                <>
                  <ProcessDatePicker
                    value={value}
                    name={`cargoLoad`}
                    title="Embarque"
                    setValue={setValue}
                    minDate={
                      minDateVoyageLoad(props.index ?? 0, allVoyages)?.date
                    }
                    maxDate={new Date()}
                    disabled={
                      shouldDisableLoad(props.index ?? 0, allVoyages)?.disabled //apague o && value
                    }
                    helperText={
                      minDateVoyageLoad(props.index ?? 0, allVoyages)
                        ?.message || "Data superior ao dia de hoje"
                    }
                  />
                  {shouldDisableLoad(props.index ?? 0, allVoyages)?.message && (
                    <Typography
                      variant="subtitle3"
                      color={theme.palette.primary.main}
                    >
                      {shouldDisableLoad(props.index ?? 0, allVoyages)?.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="ETA" xs={3} required>
            <Controller
              name={`estimatedCargoUnload`}
              control={control}
              render={({field: {value}}) => (
                <ProcessDatePicker
                  value={value}
                  name={`estimatedCargoUnload`}
                  errorName="Eta"
                  title="ETA"
                  required
                  setValue={setValue}
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Desembarque" xs={3}>
            <Controller
              name={`cargoUnload`}
              control={control}
              render={({field: {value}}) => (
                <>
                  <ProcessDatePicker
                    value={value}
                    name={`cargoUnload`}
                    title="Desembarque"
                    setValue={setValue}
                    minDate={
                      minDateVoyageUnload(props.index ?? 0, [
                        ...allVoyages,
                        modalVoyage,
                      ])?.date
                    }
                    maxDate={new Date()}
                    helperText={
                      minDateVoyageUnload(props.index ?? 0, allVoyages)
                        ?.message || "Data futura inválida"
                    }
                    disabled={
                      shouldDisableUnload(props.index ?? 0, [
                        ...allVoyages,
                        modalVoyage,
                      ])?.disabled
                    }
                  />
                  {shouldDisableUnload(props.index ?? 0, [
                    ...allVoyages,
                    modalVoyage,
                  ])?.message && (
                    <Typography
                      variant="subtitle3"
                      color={theme.palette.primary.main}
                    >
                      {
                        shouldDisableUnload(props.index ?? 0, [
                          ...allVoyages,
                          modalVoyage,
                        ])?.message
                      }
                    </Typography>
                  )}
                </>
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper title="Local de coleta" xs={3}>
            <Controller
              name={`placePickup`}
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomInput
                  name={`placePickup`}
                  placeholder="Local de coleta"
                  value={value ?? ""}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
        </Grid>
        <DialogActions sx={{padding: `0 ${pxToRem(20)} ${pxToRem(20)} 0`}}>
          <CustomButton
            size="medium"
            variant="outlined"
            onClickFn={handleCloseModal}
          >
            Cancelar
          </CustomButton>
          <CustomButton
            size="medium"
            onClickFn={handleFormSubmissionWithValidation}
            endIcon="save"
            form={formId}
          >
            Salvar
          </CustomButton>
        </DialogActions>
      </form>
    </CustomModal>
  );
};
export default AddOrEditVoyageModal;
