import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {DialogContent} from "@mui/material";
import {Grid} from "@mui/system";

import pxToRem from "hooks/usePxToRem";
import {CustomButton} from "ui";
import {ButtonWithDropdown, CustomModal} from "components";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import {ModalWrapperFooter} from "views/Tasks/components/GenericTasksModal/components";
import "./editor.style.scss";
import {FormatedFollowUp, PostFollowUpType} from "../../ProcessFollowUpTypes";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  processNumber: string;
  idGroupActivity: number;
  handlePostNewFollowUp: (followUpData: PostFollowUpType) => void;
  handleUpdateFollowUp: (
    followUpId: number,
    followUpData: Partial<PostFollowUpType>,
  ) => void;
  modalEdit?: boolean;
  setModalEdit: Dispatch<SetStateAction<boolean>>;
  currentFollowUp?: FormatedFollowUp;
  onCreateAndSendFollow: () => void;
};

const NewFollowUp = (props: Props) => {
  const {
    isOpen,
    onCloseModal,
    processNumber,
    handlePostNewFollowUp,
    idGroupActivity,
    modalEdit,
    setModalEdit,
    handleUpdateFollowUp,
    currentFollowUp,
    onCreateAndSendFollow,
  } = props;
  const [isPrivateFollowUp, setIsPrivateFollowUp] = useState(false);
  const [editorText, setEditorText] = useState("");

  const handleSubmitFollowUp = () => {
    if (modalEdit) {
      handleUpdateFollowUp(currentFollowUp?.id ?? 0, {
        content: editorText,
        isPrivate: isPrivateFollowUp,
      });
    } else {
      handlePostNewFollowUp({
        idGroupActivity: idGroupActivity,
        content: editorText,
        isPrivate: isPrivateFollowUp,
      });
    }
    handleCloseModal();
  };

  const handleCreateAndSendFollowUp = async () => {
    await handleSubmitFollowUp();
    await onCreateAndSendFollow();
  };

  const handleCloseModal = () => {
    setEditorText("");
    setIsPrivateFollowUp(false);
    setModalEdit(false);
    onCloseModal();
  };

  const onChangeTextEditorContent = (text: string) => {
    setEditorText(text);
  };

  const handleAddEditDataToInputs = () => {
    if (modalEdit && currentFollowUp) {
      setEditorText(currentFollowUp?.content);
      setIsPrivateFollowUp(currentFollowUp?.isPrivate);
    }
  };

  useEffect(() => {
    handleAddEditDataToInputs();
  }, [modalEdit, currentFollowUp]);

  const editorSizeStyle = Quill.import("attributors/style/size");
  editorSizeStyle.whitelist = ["10px", "12px", "14px", "24px"];
  Quill.register(editorSizeStyle, true);

  const textEditorStyles = {
    height: pxToRem(250),
    width: "100%",
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],

      [{list: "ordered"}, {list: "bullet"}],

      [{size: ["10px", "12px", "14px", "24px"]}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={`${
        modalEdit ? "Editar atualização" : "Nova atualização"
      } - ${processNumber}`}
      titleIcon="notes"
    >
      <DialogContent sx={{minHeight: "fit-content"}}>
        <Grid container>
          <Grid size={12}>
            <ReactQuill
              theme="snow"
              placeholder="Insira a nova atualização referente ao processo."
              value={editorText}
              onChange={onChangeTextEditorContent}
              modules={modules}
              style={textEditorStyles}
            />
          </Grid>

          <Grid
            mt={pxToRem(70)}
            size={12}
            display="flex"
            justifyContent="flex-end"
          >
            {/* Por ora, não iremos deixar essa feature habilitada para os usuários */}
            {/* <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={isPrivateFollowUp}
                  onChange={togglePrivateFollowUp}
                />
              }
              label="Follow-up interno"
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <ModalWrapperFooter>
        <Grid
          container
          display="flex"
          justifyContent="flex-end"
          gap={pxToRem(8)}
          width={"100%"}
        >
          <CustomButton onClickFn={handleCloseModal} variant="outlined">
            Cancelar
          </CustomButton>

          <ButtonWithDropdown
            options={[
              {
                actionFn: handleSubmitFollowUp,
                name: `${modalEdit ? "Salvar" : "Criar"} followUp`,
              },
            ]}
            onClickMainButton={handleCreateAndSendFollowUp}
          >
            {`${modalEdit ? "Salvar" : "Criar"} e enviar followUp`}
          </ButtonWithDropdown>
        </Grid>
      </ModalWrapperFooter>
    </CustomModal>
  );
};

export default NewFollowUp;
